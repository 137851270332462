<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 shadow p-3 mb-3 bg-white rounded">
        <div class="row m-0 p-0">
          <div class="col-4 m-0 p-0">
            <div class="form-group">
              <label for="mainfont">ค้นหา SKU </label>
              <div class="input-group mb-3">
                <input
                  type="search"
                  class="form-control"
                  placeholder="SKU Code ..."
                  v-model="data.skCode"
                  @keyup.enter="select_data()"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn bt-main"
                    @click="select_data()"
                  >
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 p-0">
          <!-- {{ Productionbysku }} -->
          <div class="col-8 m-0 p-0">
            <p class=" ">
              <span class="font-600">ชื่อสินค้า : </span>
              <span>{{
                Productionbysku.length > 0 ? Productionbysku[0].prNameTH : "-"
              }}</span>
            </p>
          </div>
          <div class="col-2 m-0 p-0">
            <p class=" ">
              <span class="font-600">Enrich : </span>
              <span>{{
                Productionbysku.length > 0 ? Productionbysku[0].enrich : "-"
              }}</span>
            </p>
          </div>
          <div class="col-2 m-0 p-0">
            <button
              type="button"
              class="btn bt-bnb"
              :disabled="Productionbysku.length == 0"
              @click="showmodal()"
            >
              เเก้ไข Enrich
            </button>
          </div>
        </div>
      </div>
      <!-- ----------------------Pre Qc---------------------- -->
      <div class="col-12 m-0 shadow p-3 mb-3 bg-white rounded">
        <div class="row m-0 p-0">
          <div class="col-12 m-0 p-0">
            <p class="font-600 ">Pre QC Content</p>
          </div>
        </div>
        <div class="row m-0 p-0" v-if="PreQcBySKU.length > 0">
          <!-- {{ PreQcBySKU }} -->
          <div class="col-12 col-xl-6 m-0 p-0">
            <p class="font-0-8s">
              <span class="font-600"
                >Pre QC Copy Writer(copyWr_Qc_Status) :
              </span>
              <span
                :class="
                  PreQcBySKU[0].copyWr_Qc_Status == 'Y'
                    ? 'badge badge-main'
                    : 'badge badge-twd'
                "
                >{{ PreQcBySKU[0].copyWr_Qc_Status }}</span
              >
            </p>
          </div>
          <div class="col-12 col-xl-6 m-0 p-0">
            <p class="font-0-8s">
              <span class="font-600"
                >Pre QC Graphic Design(graphicDsn_Qc_Status) :
              </span>
              <span
                :class="
                  PreQcBySKU[0].graphicDsn_Qc_Status == 'Y'
                    ? 'badge badge-main'
                    : 'badge badge-twd'
                "
                >{{ PreQcBySKU[0].graphicDsn_Qc_Status }}</span
              >
            </p>
          </div>

          <div class="col-12 col-xl-6 m-0 p-0">
            <p class="font-0-8s">
              <span class="font-600"
                >ข้อมูลครบถ้วน Copy Writer(missing_Data_Done) :
              </span>
              <span
                :class="
                  PreQcBySKU[0].missing_Data_Done == 'Y'
                    ? 'badge badge-main'
                    : 'badge badge-twd'
                "
                >{{ PreQcBySKU[0].missing_Data_Done }}</span
              >
            </p>
          </div>

          <div class="col-12 col-xl-6 m-0 p-0">
            <p class="font-0-8s">
              <span class="font-600"
                >ข้อมูลครบถ้วน Graphic Design(req_Image_Done) :
              </span>
              <span
                :class="
                  PreQcBySKU[0].req_Image_Done == 'Y'
                    ? 'badge badge-main'
                    : 'badge badge-twd'
                "
                >{{ PreQcBySKU[0].req_Image_Done }}</span
              >
            </p>
          </div>

          <div class="col-12 col-xl-6 m-0 p-0">
            <p class="font-0-8s">
              <span class="font-600"
                >ข้อมูลตอบกลับครบถ้วน Copy Writer(contactVd_Cw_Status) :
              </span>
              <span
                :class="
                  PreQcBySKU[0].contactVd_Cw_Status == 'Y'
                    ? 'badge badge-main'
                    : 'badge badge-twd'
                "
                >{{ PreQcBySKU[0].contactVd_Cw_Status }}</span
              >
            </p>
          </div>

          <div class="col-12 col-xl-6 m-0 p-0">
            <p class="font-0-8s">
              <span class="font-600"
                >ข้อมูลตอบกลับครบถ้วน Graphic Design(contactVd_Gd_Status) :
              </span>
              <span
                :class="
                  PreQcBySKU[0].contactVd_Gd_Status == 'Y'
                    ? 'badge badge-main'
                    : 'badge badge-twd'
                "
                >{{ PreQcBySKU[0].contactVd_Gd_Status }}</span
              >
            </p>
          </div>
          <div class="col-12 col-xl-6 m-0 p-0">
            <p class="font-0-8s">
              <span class="font-600"
                >พร้อมเขียน Copy Writer(push_data_already) :
              </span>
              <span
                :class="
                  PreQcBySKU[0].push_data_already == 'Y'
                    ? 'badge badge-main'
                    : 'badge badge-twd'
                "
                >{{ PreQcBySKU[0].push_data_already }}</span
              >
            </p>
          </div>

          <div class="col-12 col-xl-6 m-0 p-0">
            <p class="font-0-8s">
              <span class="font-600"
                >พร้อมเขียน Graphic Design(push_img_already) :
              </span>
              <span
                :class="
                  PreQcBySKU[0].push_img_already == 'Y'
                    ? 'badge badge-main'
                    : 'badge badge-twd'
                "
                >{{ PreQcBySKU[0].push_img_already }}</span
              >
            </p>
          </div>

          <div class="col-12 col-xl-6 m-0 p-0">
            <p class="font-0-8s">
              <span class="font-600"
                >ขออนุมัติ Copy Writer(copyWr_Creator_Status) :
              </span>
              <span
                :class="
                  PreQcBySKU[0].copyWr_Creator_Status == 'Y'
                    ? 'badge badge-main'
                    : 'badge badge-twd'
                "
                >{{ PreQcBySKU[0].copyWr_Creator_Status }}</span
              >
            </p>
          </div>

          <div class="col-12 col-xl-6 m-0 p-0">
            <p class="font-0-8s">
              <span class="font-600"
                >ขออนุมัติ Graphic Design(graphicDsn_Creator_Status) :
              </span>
              <span
                :class="
                  PreQcBySKU[0].graphicDsn_Creator_Status == 'Y'
                    ? 'badge badge-main'
                    : 'badge badge-twd'
                "
                >{{ PreQcBySKU[0].graphicDsn_Creator_Status }}</span
              >
            </p>
          </div>

          <div class="col-12 col-xl-6 m-0 p-0">
            <p class="font-0-8s">
              <span class="font-600"
                >อนุมัติ Copy Writer(senior_copyWr_Staus) :
              </span>
              <span
                :class="
                  PreQcBySKU[0].senior_copyWr_Staus == 'Y'
                    ? 'badge badge-main'
                    : 'badge badge-twd'
                "
                >{{ PreQcBySKU[0].senior_copyWr_Staus }}</span
              >
            </p>
          </div>

          <div class="col-12 col-xl-6 m-0 p-0">
            <p class="font-0-8s">
              <span class="font-600"
                >อนุมัติ Graphic Design(senior_graphicDsn_Status) :
              </span>
              <span
                :class="
                  PreQcBySKU[0].senior_graphicDsn_Status == 'Y'
                    ? 'badge badge-main'
                    : 'badge badge-twd'
                "
                >{{ PreQcBySKU[0].senior_graphicDsn_Status }}</span
              >
            </p>
          </div>
        </div>

        <div class="row m-0 p-0" v-else>
          <div class="col-12 m-0 p-0">
            <p class="font-600 text-center">---- No Date ----</p>
          </div>
        </div>
      </div>
      <!-- ----------------log Pre Qc------------------- -->
      <div class="col-12 m-0 shadow p-3 mb-3 bg-white rounded">
        <div class="row m-0 p-0">
          <div class="col-12 m-0 p-0">
            <p class="font-600 ">Log Pre Qc</p>
          </div>

          <div class="col-12 .m-0 p-0">
            <!-- {{ LogEnrich }} -->
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fieldsPreqc"
              :items="LogPreQc"
              outlined
              hover
              show-empty
              responsive
              class="font-0-8s"
            >
              <template #cell(No)="row">
                {{ row.index + 1 + (page_num - 1) * page_size }}
              </template>
              <template #cell(jsonData)="row">
                <vue-json-pretty  :data="JSON.parse(row.item.jsonData)" />
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <!-- ----------------log enrich------------------- -->
      <div class="col-12 m-0 shadow p-3 mb-3 bg-white rounded">
        <div class="row m-0 p-0">
          <div class="col-12 m-0 p-0">
            <p class="font-600 ">Log Enrich</p>
          </div>

          <div class="col-12 .m-0 p-0">
            <!-- {{ LogEnrich }} -->
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="LogEnrich"
              outlined
              hover
              show-empty
              responsive
              class="font-0-8s"
            >
              <template #cell(No)="row">
                {{ row.index + 1 + (page_num - 1) * page_size }}
              </template>
              <template #cell(active)="row">
                <span
                  :class="[row.item.active == 'Y' ? 'badge-main' : 'badge-twd']"
                  class="badge"
                  >{{ row.item.active !== "Y" ? "No" : "Yes" }}</span
                >
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <ModalUpdateEnrich
      :key="remodal"
      :sku="data.skCode"
      :old_enrich="data.enrich"
      @select_data="select_data"
    />
  </div>
</template>

<script>
import ModalUpdateEnrich from "@/components/AdminIT/MonitorEnrichSKU/ModalUpdateEnrich.vue";
export default {
  name: "MonitorEnrichSKU",
  components: { ModalUpdateEnrich },
  data() {
    return {
      remodal: Date.now(),
      data: { skCode: "", enrich: "" },
      LogEnrich: [],
      PreQcBySKU: [],
      Productionbysku: [],
      LogPreQc: [],

      fields: [
        { key: "assign_Id", label: "assign_Id" },
        { key: "skCode", label: "skCode" },
        { key: "old_enrich", label: "old_enrich", class: "text-center" },
        {
          key: "new_enrich",
          label: "new_enrich",
          class: "text-center",
        },
        {
          key: "latestUpdate",
          label: "latestUpdate",
          class: "text-center",
        },
        { key: "user_id", label: "Update By", class: "text-center" },
      ],
      fieldsPreqc: [
        { key: "assign_Id", label: "assign_Id" },
        { key: "skCode", label: "skCode" },

        {
          key: "update_Date",
          label: "update_Date",
        },
        {
          key: "jsonData",
          label: "jsonData",
        },
        { key: "user_id", label: "Update By" },
      ],
    };
  },
  watch: {
    "data.skCode": function(v) {
      if (v == "") {
        this.LogEnrich = [];
        this.PreQcBySKU = [];
        this.Productionbysku = [];
        this.LogPreQc = [];
      }
    },
  },
  methods: {
    showmodal() {
      this.remodal = Date.now();
      setTimeout(() => {
        this.$bvModal.show("ModalUpdateEnrich");
      }, 100);
    },
    async select_data() {
      try {
        let { skCode } = this.data;
        if (skCode == "") {
          throw `Plesse Enter skCode / กรุณาใส่ skCode`;
        }

        let data = { skCode: skCode };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `adminit/monitorenrichsku`,
          data,
          1
        );

        this.LogEnrich = getAPI.LogEnrich;
        this.PreQcBySKU = getAPI.PreQcBySKU;
        this.Productionbysku = getAPI.Productionbysku;
        this.data.enrich = this.Productionbysku[0].enrich;
        this.LogPreQc = getAPI.LogPreQc;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>
