<template>
  <b-modal
    id="ModalUpdateEnrich"
    hide-footer
    no-close-on-backdrop
    hide-header-close
    scrollable
  >
    <template #modal-header="">
      <div class="row w-100">
        <div class="col-10">
          <h5>Update Enrich : {{ sku }}</h5>
        </div>
        <div class="col-2 text-right">
          <span type="button" @click="hide()">
            x
          </span>
        </div>
      </div>
    </template>
    <div class="row m-0 p-0">
      <div
        class="col-12
        "
      >
        <div class="form-group">
          <label for="exampleInputPassword1"> Enrich</label>
          <select class="form-control   w-100" v-model="data.enrich">
            <option v-for="(e, i) in enrich" :key="i" :value="e.name">
              {{ `${e.detail}(${e.name})` }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 my-3 text-right">
        <button type="button" class="btn bt-main px-3" @click="save()">
          SAVE
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalUpdateEnrich",
  props: ["sku", "old_enrich"],
  data() {
    return {
      enrich: [],
      data: { skCode: "", enrich: "" },
    };
  },
  mounted() {
    this.getpimselectstatus();
    this.setdata();
  },
  methods: {
    setdata() {
      this.data = { skCode: this.sku, enrich: this.old_enrich };
    },
    hide() {
      this.$bvModal.hide("ModalUpdateEnrich");
    },
    async getpimselectstatus() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "get",
          `select/getpimselectstatus`,
          {},
          1
        );

        this.enrich = getAPI.data;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
        this.enrich = [];
      }
    },
    async save() {
      try {
        let { skCode, enrich } = this.data;
        if (skCode == "") {
          throw `SKU is '' Please try again / SKU = '' โปรดลองอีกครั้ง`;
        }
        if (enrich == "") {
          throw `This Function not support enrich = '' / ฟังชันนี้ไม่สนับสนุน  enrich = ''`;
        }
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `adminit/updateenrichsku`,
            { skCode: skCode, enrich: enrich },
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");

          this.hide();
          this.$emit("select_data");
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>
